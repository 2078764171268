import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/charts"

export default {
  /**
   * This action will load charts.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load charts.
   */
  async loadCharts(context, payload) {
    context.commit("setLoadingCharts", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getChartsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getChartsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateCharts", [getChartsResponse.data])
      } else {
        context.commit("setCharts", getChartsResponse.data)
      }
    }

    context.commit("setLoadingCharts", false)
  },

  /**
   * This action will add a new chart.
   * @param {*} context is the store.
   */
  async addChart(context, payload) {
    context.commit("setAddingChart", true)
    context.commit("setChartAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postChartResponse = await httpClient.post(undefined, payload)

    if (postChartResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateCharts", [postChartResponse.data])
      context.commit("setChartAdded", true)
    } else if (postChartResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setChartAddError", postChartResponse.data)
    }

    context.commit("setAddingChart", false)
  },

  /**
   * This action updates name of a chart.
   * @param {*} context is the store.
   * @param {*} chart id and name of a chart.
   */
  async updateChart(context, chart) {
    const id = chart.id
    delete chart.id
    const propertiesToBeUpdated = Object.keys(chart)
    for (const property of propertiesToBeUpdated) {
      if (chart[property] === undefined) {
        chart[property] = null
      }
    }
    context.commit("setUpdatingChart", true)
    context.commit("resetChartUpdated", propertiesToBeUpdated)
    context.commit("resetChartUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateChartResponse = await httpClient.put(`/${id}`, chart)

    if (updateChartResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateCharts", [{
        id,
        ...chart
      }])
      context.commit("setChartUpdated", propertiesToBeUpdated)
    } else if (updateChartResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setChartUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateChartResponse.data
      })
    }
    context.commit("setUpdatingChart", false)
  },

  /**
   * This action removes a chart.
   * @param {*} context is the store.
   * @param {*} payload is the id of a chart.
   */
  async removeChart(context, payload) {
    context.commit("setChartRemoved", false)
    context.commit("setRemovingChart", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteChartResponse = await httpClient.delete(`/${payload}`)

    if (deleteChartResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setChartRemoved", true)
      context.commit("removeChart", payload)
    }

    context.commit("setRemovingChart", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setCharts", new Array())
    context.commit("setLoadingCharts", undefined)
    context.commit("setAddingChart", false)
    context.commit("setChartAdded", false)
    context.commit("setUpdatingChart", false)
    context.commit("resetChartAddError")
    context.commit("resetChartUpdated")
    context.commit("resetChartUpdateError")
  }
}
