export default {
  loadingCharts: false,
  charts       : new Array(),
  addingChart  : false,
  chartAdded   : false,
  chartAddError: undefined,
  updatingChart: false,
  chartUpdated : {
    name         : false,
    savedFilterId: false,
    type         : false,
    dimension    : false,
    metric       : false,
    subdivision  : false,
    creatorId    : false
  },
  chartUpdateError: {
    name         : null,
    savedFilterId: null,
    type         : null,
    dimension    : null,
    metric       : null,
    subdivision  : null,
    creatorId    : null
  },
  removingChart: false,
  chartRemoved : false
}