/**
 * @file It contains all the vuex export module state variables.
 */
export default {
  exports         : [],
  addingExport    : false,
  updatingExport  : false,
  exportUpdated   : false,
  printPdfData    : new Object(),
  printPdfFileName: undefined
}