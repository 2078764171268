import { defaultTableHeaderClasses } from "@/constants"

export const CHANNEL_TRANSLATION_CONFIGURATIONS = {
  headers: [{
    text : "394",
    align: "left",
    value: "languageName",
    class: defaultTableHeaderClasses
  },
  {
    text : "395",
    align: "left",
    value: "enabled",
    class: defaultTableHeaderClasses
  },
  {
    text : "396",
    align: "left",
    value: "enableTranslationToEnglish",
    class: defaultTableHeaderClasses
  },
  {
    text : "2422",
    align: "left",
    value: "human",
    class: defaultTableHeaderClasses
  },
  {
    text : "2423",
    align: "left",
    value: "machineText",
    class: defaultTableHeaderClasses
  },
  {
    text : "2424",
    align: "left",
    value: "machineSpeech",
    class: defaultTableHeaderClasses
  }]
}