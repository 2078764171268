import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"
import { downloadFile } from "@/utils"

const path = "/v1/message-items"

export default {
  /**
   * This action will load a particular messageItem.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadMessageItems(context, payload) {
    context.commit("setLoadingMessageItems", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getMessageItemsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getMessageItemsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateMessageItems", getMessageItemsResponse.data)
      } else {
        context.commit("setMessageItems", getMessageItemsResponse.data)
      }
    } else {
      // something went wrong while getting messageItems
    }

    context.commit("setLoadingMessageItems", false)
  },

  /**
   * This action will add a message.
   * @param {*} context is the store.
   */
  async addMessageItem(context, payload) {
    context.commit("setAddingMessageItem", true)
    context.commit("setMessageItemAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postMessageItemResponse = await httpClient.post(undefined, payload)

    if (postMessageItemResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateMessageItems", [postMessageItemResponse.data])
      context.commit("setMessageItemAdded", true)
    } else if (postMessageItemResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setMessageItemAddError", postMessageItemResponse.data)
    } else {
      // something went wrong while adding a message
    }

    context.commit("setAddingMessageItem", false)
  },

  /**
   * This action will add a message.
   * @param {*} context is the store.
   */
  async humanTranslateMessageItem(context, payload) {
    context.commit("setHumanTranslatingMessageItem", true)
    context.commit("setMessageItemHumanTranslated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postHumanTranslateMessageItemResponse = await httpClient.post(`/${payload}/human-translation`)

    if (postHumanTranslateMessageItemResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setMessageItemHumanTranslated", true)
    } else {
      // something went wrong while adding a message
    }

    context.commit("setHumanTranslatingMessageItem", false)
  },

  /**
   * This action updates summary of an messageItem.
   * @param {*} context is the store.
   * @param {*} payload id and summary of an messageItem.
   */
  async updateMessageItem(context, messageItem) {
    const id = messageItem.id
    delete messageItem.id
    const propertiesToBeUpdated = Object.keys(messageItem)
    for (const property of propertiesToBeUpdated) {
      if (messageItem[property] === undefined) {
        messageItem[property] = null
      }
    }
    context.commit("setUpdatingMessageItem", propertiesToBeUpdated)
    context.commit("resetMessageItemUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateMessageItemResponse = await httpClient.put(`/${id}`, messageItem)

    if (updateMessageItemResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateMessageItems", [{
        id,
        ...messageItem
      }])
      context.commit("setMessageItemUpdated", propertiesToBeUpdated)
    } else {
      // something went wrong while updating an messageItem
    }

    context.commit("resetUpdatingMessageItem", propertiesToBeUpdated)
  },

  /**
   * This action downloads file associated with message item
   * @param {*} context is the store.
   * @param {*} payload message item whose associated file has to be downloaded
   */
  async downloadFile(context, { id, value, audioFile = false }) {
    context.commit("setDownloadingFile", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getDownloadURLResponse = await httpClient.get(`/${id}/download-url`)
    if (getDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      if (audioFile) {
        context.commit("updateMessageItems", [{
          id,
          fileUrl: getDownloadURLResponse.data
        }])
      } else {
        await downloadFile(getDownloadURLResponse.data, value.md5, value.name)
      }
    }
    context.commit("setDownloadingFile", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setMessageItems", new Array())
    context.commit("setLoadingMessageItems", undefined)
    context.commit("setDownloadingFile", undefined)
    context.commit("setAddingMessageItem", false)
    context.commit("setMessageItemAdded", false)
    context.commit("setHumanTranslatingMessageItem", false)
    context.commit("setMessageItemHumanTranslated", false)
    context.commit("resetUpdatingMessageItem")
    context.commit("resetMessageItemUpdated")
  }
}