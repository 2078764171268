import Vue from "vue"
import ECharts from "vue-echarts"
import { use } from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import { BarChart, LineChart, PieChart } from "echarts/charts"
import { TooltipComponent, TitleComponent, LegendComponent, ToolboxComponent, GridComponent } from "echarts/components"

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  PieChart,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent
])

Vue.component("Charts", ECharts)