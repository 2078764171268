import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeAnalyticsView = (to, from) => {
  const loggedInUserPolicies     = store.getters["accessControl/loggedInUserPolicies"]
  const isCustomAnalyticsEnabled = store.getters["configurations/isCustomAnalyticsEnabled"]

  if (!loggedInUserPolicies["Analytics view"] && !loggedInUserPolicies["CustomView view"]) {
    return "not-found"
  }

  if (!isCustomAnalyticsEnabled) {
    return { name: "analytics-views" }
  }

  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)

  const promisesArrayToBeResolved = []
  const loadCustomViewsPromise    = store.dispatch("customViews/loadCustomViews")
  promisesArrayToBeResolved.push(loadCustomViewsPromise)

  const users                    = store.getters["users/users"]
  const loggedInUser             = store.getters["auth/loggedInUser"]
  const userCustomViewPreference = users.find(user => user.id === loggedInUser.id)?.customViewId

  if(!userCustomViewPreference && !["analytics-views", "analytics-view"].includes(from.name)) {
    return { name: "analytics-views" }
  }

  if (userCustomViewPreference && userCustomViewPreference !== +to.params.id && !["analytics-views", "analytics-view"].includes(from.name)) {
    return {
      name  : "analytics-view",
      params: { id: userCustomViewPreference }
    }
  }

  if (!["analytics-view"].includes(from.name)) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ANALYTICS))
    store.commit("shared/setBreadcrumbs", undefined)

    const loadFiltersPromise = store.dispatch("filters/loadFilters")
    promisesArrayToBeResolved.push(loadFiltersPromise)

    loadCustomViewsPromise.then(() => {
      if (loggedInUserPolicies["Group view"]) {
        const groupsPromise = store.dispatch("groups/loadGroups")
        promisesArrayToBeResolved.push(groupsPromise)
      }
    })

    if (loggedInUserPolicies["IssueSearch view"]) {
      const loadIssuesInitialPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
        criteria: {
          searchText               : null,
          issuesFilters            : {},
          searchThroughAllIssueData: false
        },
        excludeFilterPreferences: true
      })
      promisesArrayToBeResolved.push(loadIssuesInitialPromise)

      if (loggedInUserPolicies["Domain view"]) {
        const domainsPromise = store.dispatch("domains/loadDomains")
        promisesArrayToBeResolved.push(domainsPromise)
      }
      if (loggedInUserPolicies["Channel view"]) {
        const loadChannelsPromise = store.dispatch("channels/loadChannels")
        promisesArrayToBeResolved.push(loadChannelsPromise)
      }
      if (loggedInUserPolicies["Language view"]) {
        const loadLanguagesPromise = store.dispatch("languages/loadLanguages")
        promisesArrayToBeResolved.push(loadLanguagesPromise)
      }

      const usersPromise = store.dispatch("users/loadUsers")
      promisesArrayToBeResolved.push(usersPromise)

      if (loggedInUserPolicies["Chart view"]) {
        const loadChartsPromise = store.dispatch("charts/loadCharts")
        promisesArrayToBeResolved.push(loadChartsPromise)
      }

      if (loggedInUserPolicies["IssueField view"]) {
        const loadIssueFieldsPromise = store.dispatch("issueFields/loadIssueFields")
        promisesArrayToBeResolved.push(loadIssueFieldsPromise)
      }

      if (loggedInUserPolicies["FieldV2 view"]) {
        const loadFieldsPromise = store.dispatch("fields/loadFieldsV2")
        promisesArrayToBeResolved.push(loadFieldsPromise)
      }

      if (loggedInUserPolicies["FormTemplate view"]) {
        const loadFormTemplatesPromise = store.dispatch("formTemplates/loadFormTemplates")
        promisesArrayToBeResolved.push(loadFormTemplatesPromise)
      }

      if (loggedInUserPolicies["OptionListItem view"]) {
        const loadOptionListItemsPromise = store.dispatch("optionListItems/loadOptionListItems")
        promisesArrayToBeResolved.push(loadOptionListItemsPromise)
      }

      if (loggedInUserPolicies["IssueFormTemplate view"]) {
        const loadIssueFormTemplatesPromise = store.dispatch("issueFormTemplates/loadIssueFormTemplates")
        promisesArrayToBeResolved.push(loadIssueFormTemplatesPromise)
      }

      if (loggedInUserPolicies["FormTemplateConfiguration view"]) {
        const loadFormTemplateConfigurationsPromise = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations")
        promisesArrayToBeResolved.push(loadFormTemplateConfigurationsPromise)
      }

      const pollPromise = loadIssuesInitialPromise.then(() => {
        const issueSearch       = store.getters["issueSearch/issueSearch"]
        const pollSearchPromise = pollIssueSearch(store, issueSearch.id, true)
        promisesArrayToBeResolved.push(pollSearchPromise)
        return pollSearchPromise
      })
      promisesArrayToBeResolved.push(pollPromise)
    }
  }

  const customViews                   = store.getters["customViews/customViews"]
  const customViewIds                 = customViews.map(customView => customView.id)
  const customViewUpdatePolicyPromise = store.dispatch("accessControl/loadLoggedInUserAccess", [{
    policies   : ["CustomView update"],
    resourceIds: customViewIds
  }])
  promisesArrayToBeResolved.push(customViewUpdatePolicyPromise)

  const loadCustomView = store.dispatch("customViews/loadCustomView", { id: +to.params.id })
  promisesArrayToBeResolved.push(loadCustomView)

  const chartProcessingPromise = new Promise(resolve => {
    loadCustomView.then(() => {
      const customViewsWithChart = store.getters["customViews/customViews"]
      const customViewWithCharts = customViewsWithChart.find(customView => customView.id === +to.params.id)
      const calledFilterIds      = []
      const chartPromises        = []

      for (const chart of (customViewWithCharts?.charts ?? [])) {
        const filterId = chart.savedFilterId

        if (filterId && !calledFilterIds.includes(filterId) && loggedInUserPolicies["IssueSearch view"]) {
          calledFilterIds.push(filterId)

          const chartPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
            filterId,
            excludeFilterPreferences: true,
            excludeColumnPreferences: true
          }).then(() => {
            const issuesSearch = store.getters["issueSearch/issueSearch"]
            store.commit("customViews/setCustomViewChartFiltersToReload", {
              filterIds   : [filterId],
              customViewId: customViewWithCharts.id
            })
            if (issuesSearch.status !== "completed") {
              return pollIssueSearch(store, issuesSearch.id, false)
            }
            return Promise.resolve()
          })

          chartPromises.push(chartPromise)
        }
      }

      Promise.all(chartPromises).then(resolve)
    })
  })

  promisesArrayToBeResolved.push(chartProcessingPromise)

  Promise.all(promisesArrayToBeResolved)
    .then(() => {
      store.commit("shared/setProgressBarPromisesPending", false)
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("customViews/resetCustomViewChartFiltersToReload")
    })
}

const pollIssueSearch = (store, id, loadIssueSearch) => {
  return new Promise((resolve, reject) => {
    const poll = async () => {
      try {
        await store.dispatch("issueSearch/loadIssueSearch", { id })
        const issuesSearch = store.getters["issueSearch/issueSearch"]
        const status       = issuesSearch.status

        if (status === "completed") {
          let pollPromise = Promise.resolve()
          if (loadIssueSearch) {
            pollPromise = store.dispatch("issueSearch/loadIssuesFromIssueSearchId", {
              issueSearchId: issuesSearch.id,
              properties   : [],
              kpiData      : true
            })
          } else {
            store.commit("issueSearch/updateFilterIdWithIssueIds", [{ filterId: issuesSearch.filterId, issueIds: issuesSearch.result }])
          }
          resolve(pollPromise)
        } else {
          setTimeout(poll, 1000)
        }
      } catch (error) {
        reject(error)
      }
    }

    poll()
  })
}