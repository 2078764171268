export default {
  isLoadingCharts: state => state.loadingCharts,
  charts         : state => state.charts,
  isChartAdded   : state => state.chartAdded,
  isAddingChart  : state => state.addingChart,
  isUpdatingChart: state => state.updatingChart,
  isChartUpdated : state => state.chartUpdated.name || state.chartUpdated.savedFilterId || state.chartUpdated.type
  || state.chartUpdated.dimension || state.chartUpdated.metric || state.chartUpdated.subdivision,
  chartNameUpdateError: state => state.chartUpdateError.name,
  chartAddError       : state => state.chartAddError,
  isChartRemoved      : state => state.chartRemoved,
  isRemovingChart     : state => state.removingChart
}