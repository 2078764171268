import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeAnalyticsViews = async (to, from) => {

  const loggedInUserPolicies     = store.getters["accessControl/loggedInUserPolicies"]
  const isCustomAnalyticsEnabled = store.getters["configurations/isCustomAnalyticsEnabled"]

  if (!loggedInUserPolicies["Analytics view"]) {
    return "not-found"
  }
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  const promisesArrayToBeResolved = []

  if (isCustomAnalyticsEnabled && loggedInUserPolicies["CustomView view"]) {
    const loadCustomViewsPromise = store.dispatch("customViews/loadCustomViews")
    promisesArrayToBeResolved.push(loadCustomViewsPromise)
    const users                    = store.getters["users/users"]
    const loggedInUser             = store.getters["auth/loggedInUser"]
    const userCustomViewPreference = users.find(user => user.id === loggedInUser.id)?.customViewId

    if (userCustomViewPreference
      && userCustomViewPreference !== +to.params.id
      && !["analytics-views", "analytics-view"].includes(from.name)
    ) {
      return {
        name  : "analytics-view",
        params: {
          id: userCustomViewPreference
        }
      }
    }
  }

  if (!["analytics-views", "analytics-view"].includes(from.name)) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ANALYTICS))
    store.commit("shared/setBreadcrumbs", undefined)

    if (loggedInUserPolicies["IssueSearch view"]) {
      const loadIssuesInitialPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
        criteria: {
          searchText               : null,
          issuesFilters            : {},
          searchThroughAllIssueData: false
        },
        excludeFilterPreferences: true
      })
      promisesArrayToBeResolved.push(loadIssuesInitialPromise)

      if (loggedInUserPolicies["Domain view"]) {
        const domainsPromise = store.dispatch("domains/loadDomains")
        promisesArrayToBeResolved.push(domainsPromise)
      }
      if (loggedInUserPolicies["Channel view"]) {
        const loadChannelsPromise = store.dispatch("channels/loadChannels")
        promisesArrayToBeResolved.push(loadChannelsPromise)
      }
      if (loggedInUserPolicies["Language view"]) {
        const loadLanguagesPromise = store.dispatch("languages/loadLanguages")
        promisesArrayToBeResolved.push(loadLanguagesPromise)
      }

      const pollPromise = loadIssuesInitialPromise.then(() => {
        const issueSearch       = store.getters["issueSearch/issueSearch"]
        const pollSearchPromise = pollIssueSearch(store, issueSearch.id, true)
        promisesArrayToBeResolved.push(pollSearchPromise)
        return pollSearchPromise
      })
      promisesArrayToBeResolved.push(pollPromise)
    }
  }

  Promise.all(promisesArrayToBeResolved)
    .then(() => {
      store.commit("shared/setProgressBarPromisesPending", false)
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("customViews/resetCustomViewChartFiltersToReload")
    })
}

const pollIssueSearch = (store, id, loadIssueSearch) => {
  return new Promise((resolve, reject) => {
    const poll = async () => {
      try {
        await store.dispatch("issueSearch/loadIssueSearch", { id })
        const issuesSearch = store.getters["issueSearch/issueSearch"]
        const status       = issuesSearch.status

        if (status === "completed") {
          let pollPromise = Promise.resolve()
          if (loadIssueSearch) {
            pollPromise = store.dispatch("issueSearch/loadIssuesFromIssueSearchId", {
              issueSearchId: issuesSearch.id,
              properties   : [],
              kpiData      : true
            })
          } else {
            store.commit("issueSearch/updateFilterIdWithIssueIds", [{ filterId: issuesSearch.filterId, issueIds: issuesSearch.result }])
          }
          resolve(pollPromise)
        } else {
          setTimeout(poll, 1000)
        }
      } catch (error) {
        reject(error)
      }
    }

    poll()
  })
}