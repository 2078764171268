export default {
  loadingMessages   : undefined,
  messages          : new Array(),
  addingMessage     : false,
  messageAdded      : false,
  removingMessage   : false,
  messageRemoved    : false,
  messageAddError   : undefined,
  messageUpdateError: {
    status: false
  },
  updatingMessage: {
    status              : false,
    aiTranscriptApproved: false
  },
  messageUpdated: {
    status              : false,
    aiTranscriptApproved: false
  }
}