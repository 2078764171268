export default {
  isLoadingMessages        : state => state.loadingMessages,
  messages                 : state => state.messages,
  isMessageAdded           : state => state.messageAdded,
  isAddingMessage          : state => state.addingMessage,
  isMessageRemoved         : state => state.messageRemoved,
  isRemovingMessage        : state => state.removingMessage,
  isUpdatingStatus         : state => state.updatingMessage.status,
  isStatusUpdated          : state => state.messageUpdated.status,
  isUpdatingMessageApproval: state => state.updatingMessage.aiTranscriptApproved,
  isMessageApprovalUpdated : state => state.messageUpdated.aiTranscriptApproved
}