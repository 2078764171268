export default {
  isLoadingCustomViews          : state => state.loadingCustomViews,
  customViews                   : state => state.customViews,
  isCustomViewAdded             : state => state.customViewAdded,
  isAddingCustomView            : state => state.addingCustomView,
  isUpdatingCustomViewName      : state => state.updatingCustomView.name,
  isCustomViewNameUpdated       : state => state.customViewUpdated.name,
  customViewNameUpdateError     : state => state.customViewUpdateError.name,
  customViewAddError            : state => state.customViewAddError,
  isRemovingCustomView          : state => state.removingCustomView,
  isCustomViewRemoved           : state => state.customViewRemoved,
  isUpdatingCustomViewAccess    : state => state.updatingCustomView.access,
  isCustomViewAccessUpdated     : state => state.customViewUpdated.access,
  customViewChartFiltersToReload: state => state.customViewChartFiltersToReload,
  isReloadAllCharts             : state => state.reloadAllCharts,
  isLoadCustomViewError         : state => state.loadCustomViewError
}